/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import { rhythm } from '../utils/typography';
import colors from '../utils/colors';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname === rootPath) {
    header = (
      <>
        <div style={{ paddingTop: '20vh' }} />
        <h3
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
            to="/"
          >
            {title}
          </Link>
        </h3>
      </>
    );
  } else {
    header = (
      <h3
        style={{
          fontFamily: 'Montserrat, sans-serif',
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: colors.red,
          }}
          to="/"
        >
          {title}
        </Link>
      </h3>
    );
  }
  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: rhythm(27),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      {/* <footer> */}
      {/*  © {new Date().getFullYear()}, Built with */}
      {/*  {` `} */}
      {/*  <a href="https://www.gatsbyjs.org">Gatsby</a> */}
      {/* </footer> */}
    </div>
  );
};

export default Layout;
