import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import * as d3 from 'd3';
import textures from 'textures';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';
import colors from '../utils/colors';

const Biosmose = () => {
  const biosmose = css`
    position: absolute;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    left: 0;
    top:0;
  `;
  const ref = useRef();
  const [width, setWidth] = useState(3000);
  useLayoutEffect(() => {
    const updateSize = () => {
      if (ref && ref.current) {
        setWidth(ref.current.getBoundingClientRect().width);
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <div css={biosmose}>
      <svg
        width="100%"
        height="900px"
        ref={ref}
      >
        <Circle x={10} y={20} r={59} color={colors.blue} pattern />
        <Circle x={0} y={70} r={30} color={colors.yellow} />

        <Circle x={width + 20} y={290} r={59} color={colors.blue} pattern />
        <Circle x={width - 70} y={320} r={13} color={colors.red} />
      </svg>
    </div>
  );
};

const Circle = ({
  x, y, r, color, pattern,
}) => {
  const ref = useRef();

  useEffect(() => {
    const svgElement = d3.select(ref.current);

    const t = textures.lines().thicker();

    svgElement.call(t);

    svgElement.append('circle')
      .attr('cx', x)
      .attr('cy', y)
      .attr('r', r)
      .style('stroke', color)
      .style('stroke-width', 0)
      .style('fill', pattern ? t.url() : color);

    svgElement.selectAll('path')
      .style('stroke', color);
  }, []);

  useEffect(() => {
    const svgElement = d3.select(ref.current);

    svgElement.select('circle')
      .transition()
      .attr('cx', x)
      .attr('cy', y)
      .attr('r', r);
  }, [x, y, r, color]);

  return (
    <g ref={ref} />
  );
};

export default Biosmose;

Circle.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  r: PropTypes.number.isRequired,
  color: PropTypes.string,
  pattern: PropTypes.bool,
};
