/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { css } from '@emotion/core';
import Biosmose from './Biosmose';

const socialIcon = css`
  box-shadow: none;
  margin-right: 19px;
  font-size: 19px;
  color: #FF5252;
`;

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            linkedin
          }
        }
      }
    }
  `);

  const { social } = data.site.siteMetadata;
  return (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>
          Hi, I'm
          {' '}
          <a href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noopener noreferrer">
          Hassen
          </a>
.
          I'm a Product engineer based in Paris 🇫🇷.
          I'm currently building data products at
          {' '}
          <a target="_blank" href="https://yooi.com" rel="noopener noreferrer">
          YOOI
          </a>
.
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          width: '100px',
          paddingBottom: '12px',
        }}
      >
        <a css={socialIcon} href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a css={socialIcon} href={`https://www.linkedin.com/in/${social.linkedin}/`} target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>

      <Biosmose />
    </>
  );
};

export default Bio;
