import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';
import colors from './colors';


const theme = {
  title: 'Wordpress Theme 2016 customized',
  baseFontSize: '16px',
  baseLineHeight: 1.75,
  scaleRatio: 5 / 2,
  googleFonts: [
    // {
    //   name: "Montserrat",
    //   styles: ["700"],
    // },
    {
      name: 'Nunito',
      styles: ['400', '500', '600', '700'],
    },
  ],
  // headerFontFamily: ['Montserrat', 'Georgia', 'serif'],
  headerFontFamily: ['Merriweather', 'Georgia', 'serif'],
  // bodyFontFamily: ['Nunito', 'Georgia', 'serif'],
  bodyFontFamily: ['Merriweather', 'Georgia', 'serif'],
  bodyColor: colors.darkBlue,
  linkColor: colors.blue,
  backgroundColor: colors.white,
  headerWeight: 900,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options) => ({
    body: {
      backgroundColor: options.backgroundColor,
    },
    h1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    'blockquote > :last-child': {
      marginBottom: 0,
    },
    'blockquote cite': {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    'blockquote cite:before': {
      content: '"— "',
    },
    ul: {
      listStyle: 'disc',
    },
    'ul,ol': {
      marginLeft: '30px',
    },
    [MOBILE_MEDIA_QUERY]: {
      'ul,ol': {
        marginLeft: rhythm(1),
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    'h1,h2,h3,h4,h5,h6': {
      marginTop: rhythm(2),
    },
    h4: {
      letterSpacing: '0.140625em',
      textTransform: 'uppercase',
    },
    h6: {
      fontStyle: 'italic',
    },
    a: {
      boxShadow: '0 1px 0 0 currentColor',
      color: options.linkColor,
      textDecoration: 'none',
    },
    'a:hover,a:active': {
      boxShadow: 'none',
    },
    'mark,ins': {
      background: '#007acc',
      color: 'white',
      padding: `${rhythm(1 / 16)} ${rhythm(1 / 8)}`,
      textDecoration: 'none',
    },
    hr: {
      background: options.linkColor,
    },
    'a.gatsby-resp-image-link': {
      boxShadow: 'none',
    },
    'a.anchor': {
      boxShadow: 'none',
    },
    'a.anchor svg[aria-hidden="true"]': {
      stroke: 'rgb(255, 167, 196)',
    },
    'p code': {
      fontSize: '1rem',
    },
    'li code': {
      fontSize: '1rem',
    },
    blockquote: {
      marginLeft: '-1.75rem',
      marginRight: '1.75rem',
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: '1.42188rem',
      paddingRight: 0,
      paddingTop: 0,
      marginBottom: '1.75rem',
      fontSize: '1.20112rem',
      lineHeight: '1.75rem',
      color: 'inherit',
      fontStyle: 'italic',
      borderLeft: '0.32813rem solid hsla(0,0%,0%,0.9)',
      borderLeftColor: 'inherit',
      opacity: 0.8,
    },
    'blockquote.translation': {
      fontSize: '1em',
    },
  }),
};

export default theme;
